import React from "react"
import { Link } from "gatsby"

export const Beursault: React.FC = () => (
  <div>
    <h3>Information</h3>

    <p>
      <ul>
        <li>
          Dans l'impossibilité d'intégrer les résultats du Championnat Régional Beursault sur le site de la FFTA,
          ces résultats ont été fusionnés avec le concours d'Éclaron.<br />
          De cette manière tous les résultats sont connus de la FFTA par contre le classement régional ne peut être vu sur le site de la FFTA.{" "}
        </li>
      </ul>
    </p>

  </div >
)
